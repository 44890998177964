import React, { FC, useEffect, useRef, useState } from 'react'
import styles from './SocialsBanner.module.scss'
import gsap from 'gsap'
import cn from 'classnames'
import LogoIcon from '../../public/icons/logo-header.svg'
import TgLogo from '../../public/images/telegram-banner-logo.svg'
import TgLogoSmall from '../../public/images/telegram-small-banner-logo.svg'
import VkLogo from '../../public/images/vk-banner-logo.svg'
import VkLogoSmall from '../../public/images/vk-small-banner-logo.svg'
import YoutubeLogo from '../../public/images/youtube-banner-logo.svg'
import YoutubeLogoSmall from '../../public/images/youtube-small-banner-logo.svg'
import TwitterLogo from '../../public/images/twitter-banner-logo.svg'
import TwitterSmall from '../../public/images/twitter-small-banner-logo.svg'
import DzenLogo from '../../public/images/dzen-banner-logo.svg'
import DzenLogoSmall from '../../public/images/dzen-small-banner-logo.svg'
import { getSocName } from '../../utils/getSocName'
import { Socnet } from '../../interfaces'
import Tg from '../../public/icons/tg.svg'
import Vk from '../../public/icons/vk.svg'
import Yt from '../../public/icons/yt.svg'
import Tw from '../../public/icons/tw.svg'
import Ya from '../../public/icons/dz-new.svg'
import FooterLogo from '../../public/icons/footer-logo.svg'
import useWindowSize from '../../hooks/useWindowSize'
import Link from 'next/link'

interface MainBanner {
  className?: string
}

type SchemeInfo = {
  [key: string]: {
    label: string
    text: string
    centeredLogo: {
      logo: JSX.Element
      href: string
    }
  }
}

type SocialInfo = {
  code: string
  scheme: string
  href: string
  icon: JSX.Element
}

const SocialsBanner: FC<MainBanner> = ({ className }) => {
  const [scheme, setScheme] = useState<string>('scheme-1')
  const { width } = useWindowSize()
  const textRef = useRef<HTMLParagraphElement>(null)
  const logoRef = useRef<HTMLAnchorElement>(null)

  const handleSchemeChange = (newScheme: string) => {
    if (newScheme !== scheme) {
      gsap.to(logoRef.current, { opacity: 0, duration: 0.6 })
    }

    setScheme(newScheme)
  }

  useEffect(() => {
    const textElement = textRef.current
    const logoElement = logoRef.current

    if (!textElement || !logoElement) return

    gsap.fromTo(logoElement, { opacity: -1 }, { opacity: 1, duration: 0.6 })

    gsap.to(textElement, {
      opacity: 0,
      y: -20,
      duration: 0.3,
      onComplete: () => {
        textElement.innerHTML = schemesInfo[scheme].text
        gsap.to(textElement, {
          opacity: 1,
          y: 0,
          duration: 0.3,
        })
      },
    })
  }, [scheme])

  const schemesInfo: SchemeInfo = {
    'scheme-1': {
      label: 'telegram',
      text: 'Подписывайтесь на&nbsp;&laquo;Код Дурова&raquo; в&nbsp;Telegram и&nbsp;мгновенно узнавайте о&nbsp;самых свежих инфоповодах из&nbsp;мира технологий',
      centeredLogo: {
        logo: width > 1100 ? <TgLogo /> : <TgLogoSmall />,
        href: 'https://t.me/d_code',
      },
    },
    'scheme-2': {
      label: 'vk',
      text: 'Присоединяйтесь к&nbsp;&laquo;Коду Дурова&raquo; ВКонтакте для обсуждения последних IT-трендов',
      centeredLogo: {
        logo: width > 1300 ? <VkLogo /> : <VkLogoSmall />,
        href: 'https://vk.com/kod',
      },
    },
    'scheme-3': {
      label: 'youtube',
      text: 'Коротко о&nbsp;главном в&nbsp;формате Shorts&nbsp;&mdash; на&nbsp;YouTube-канале &laquo;Кода Дурова&raquo;',
      centeredLogo: {
        logo: width > 1200 ? <YoutubeLogo /> : <YoutubeLogoSmall />,
        href: 'https://www.youtube.com/@kod_durovaa',
      },
    },
    'scheme-4': {
      label: 'twitter',
      text: 'Следите за&nbsp;&laquo;Кодом Дурова&raquo; в&nbsp;X.&nbsp;Внутри&nbsp;&mdash; актуальные IT-события и&nbsp;мнения экспертов',
      centeredLogo: {
        logo: width > 992 ? <TwitterLogo /> : <TwitterSmall />,
        href: 'https://twitter.com/durovcode',
      },
    },
    'scheme-5': {
      label: 'dzen',
      text: 'Читайте &laquo;Код Дурова&raquo; в&nbsp;Дзене. Внутри&nbsp;&mdash; новости IT-мира, обзоры гаджетов и&nbsp;аналитика',
      centeredLogo: {
        logo: width > 1200 ? <DzenLogo /> : <DzenLogoSmall />,
        href: 'https://dzen.ru/kod',
      },
    },
  }

  const SOCIALS: SocialInfo[] = [
    {
      code: 'tg',
      scheme: 'scheme-1',
      href: 'https://t.me/d_code',
      icon: <Tg />,
    },
    {
      code: 'vk',
      scheme: 'scheme-2',
      href: 'https://vk.com/kod',
      icon: <Vk />,
    },
    {
      code: 'yt',
      scheme: 'scheme-3',
      href: 'https://www.youtube.com/@kod_durovaa',
      icon: <Yt />,
    },
    {
      code: 'tw',
      scheme: 'scheme-4',
      href: 'https://twitter.com/durovcode',
      icon: <Tw />,
    },
    {
      code: 'ya',
      scheme: 'scheme-5',
      href: 'https://dzen.ru/kod',
      icon: <Ya />,
    },
  ]

  const subscribeButtonContent = SOCIALS.filter((i) => i.scheme === scheme)[0]

  const renderSocialLinks = (socials) => (
    <ul className={styles.soc}>
      {socials.map((s) => (
        <li
          className={styles.socItem}
          key={s.code}
          onMouseEnter={() => handleSchemeChange(s.scheme)}
        >
          <span
            className={cn(
              styles.socLink,
              styles[scheme],
              s.scheme === scheme && styles.selected
            )}
            title={getSocName(s.code as Socnet)}
          >
            {s.icon}
          </span>
        </li>
      ))}
    </ul>
  )
  return (
    <div className={cn(styles.container, styles[scheme])}>
      <div className={cn(styles.content, className)}>
        <div className={styles.info}>
          {renderSocialLinks(SOCIALS)}
          <p ref={textRef} className={cn(styles.text, styles[scheme])}></p>
          <FooterLogo className={cn(styles.footerLogo, styles[scheme])} />
        </div>

        <LogoIcon className={cn(styles.mainLogo, styles[scheme])} />

        <Link
          href={schemesInfo[scheme].centeredLogo.href}
          ref={logoRef}
          className={styles.centeredLogo}
          target="_blank"
          aria-label={`Ссылка на ${schemesInfo[scheme].label}`}
        >
          {schemesInfo[scheme].centeredLogo.logo}
        </Link>

        <Link
          href={subscribeButtonContent.href}
          target="_blank"
          className={cn(styles.button, styles[scheme])}
        >
          Подписаться {subscribeButtonContent.icon}
        </Link>

        <span className={cn(styles.decor, styles.one, styles[scheme])} />
        <span className={cn(styles.decor, styles.two, styles[scheme])} />
        <span className={cn(styles.decor, styles.three, styles[scheme])} />
        <span className={cn(styles.decor, styles.four, styles[scheme])} />
        <span className={cn(styles.decor, styles.five, styles[scheme])} />
      </div>
      <div className={styles.cornerWrapper}>
        <span
          className={cn(styles.corner, styles['left-top'], styles[scheme])}
        />
        <span
          className={cn(styles.corner, styles['left-bottom'], styles[scheme])}
        />
        <span
          className={cn(styles.corner, styles['right-top'], styles[scheme])}
        />
        <span
          className={cn(styles.corner, styles['right-bottom'], styles[scheme])}
        />
      </div>
    </div>
  )
}

export default SocialsBanner
